import './App.css';
import Background from './Background';
import NavBar from './NavBar'; // Import NavBar

function App() {

  return (
    <div className="App">
      {/* <NavBar /> */}
      <Background />

    </div>
  );
}

export default App;
